import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
//@ts-ignore
import * as styles from './trustpilot.module.css';

interface Props {
    code: {
        value: string;
    };
}
const TrustPilot: React.FC<Props> = ({ code }) => {
    useEffect(() => {
        let url =
            '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.type = 'text/javascript';
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, []);
    let html = code.value;
    html = html.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    html = html.replace(/\n/g, '');
    let processedHtmlTags = parse(html);
    return (
        <>
            <div className={styles.reviews}>{processedHtmlTags}</div>
        </>
    );
};
export default TrustPilot;
export const fragmentTrustPilot = graphql`
    fragment TrustPilot on kontent_item_component___trust_pilot_reviews {
        elements {
            code {
                value
            }
        }
    }
`;
